<template>
  <BaseTootlipModal v-if="freespin" :link="readMoreUrl">
    <template #title>
      {{ t('bonuses.freespinModalTilte', [freespin?.freespinNumber ?? 0]) }}
    </template>
    <template #subtitle>
      {{ t('bonuses.freespinModalSubTitle') }}
    </template>
    <TooltipModalBlock
      :title="t('bonuses.tooltipModalParameters')"
      data-t="parameters-block"
    >
      <TooltipModalBlockItem
        :termin="t('bonuses.freespinModalAmount')"
        :description="freespinBetAmount"
      />
      <TooltipModalBlockItem :termin="t('bonuses.freespinModalMaxWin')">
        {{ formatCrypto(freespin.maxRewardAmount ?? 0, rewardCurrencyCode) }}
      </TooltipModalBlockItem>
      <TooltipModalBlockItem
        :termin="t('bonuses.tooltipModalRewardCurrency')"
        :description="rewardCurrencyCode"
      />
      <TooltipModalBlockItem
        v-if="gamesNames?.length"
        :termin="t('bonuses.freespinModalGames')"
      >
        <div class="column">
          <span v-for="game in gamesNames" :key="game">
            {{ game }}
          </span>
        </div>
      </TooltipModalBlockItem>
    </TooltipModalBlock>
    <TooltipModalBlock
      v-if="rollingMultiplier"
      :title="t('bonuses.tooltipModalWager')"
      data-t="multiplier-block"
    >
      <TooltipModalBlockItem :termin="t('bonuses.modalRolling')">
        x{{ rollingMultiplier }}
      </TooltipModalBlockItem>
      <TooltipModalBlockItem
        v-if="formattedRollingMinBetRate"
        :termin="t('bonuses.tooltipModalSportMin')"
        :description="formattedRollingMinBetRate"
      />
      <TooltipModalBlockItem
        v-if="formattedRollingMaxBetRate"
        :termin="t('bonuses.tooltipModalSportMax')"
        :description="formattedRollingMaxBetRate"
      />
      <TooltipModalBlockItem
        v-if="filteredCategoriesNames?.length"
        :termin="t('bonuses.freespinModalCasino')"
      >
        <div class="column">
          <span v-for="category in filteredCategoriesNames" :key="category">
            {{ category }}
          </span>
        </div>
      </TooltipModalBlockItem>
    </TooltipModalBlock>
  </BaseTootlipModal>
</template>

<script setup lang="ts">
import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import { useRollingData } from '../../../composables/useRollingData'
import { useFreespinsStore } from '../../../stores/useFreespinsStore'
import { useFreespin } from '../../../composables/useFreespin'
import BaseTootlipModal from '../../TooltipModal/BaseTootlipModal.vue'
import TooltipModalBlock from '../../TooltipModal/TooltipModalBlock.vue'
import TooltipModalBlockItem from '../../TooltipModal/TooltipModalBlockItem.vue'

const props = defineProps<{
  id: string
}>()

const { t } = useI18n()

const { freespins } = storeToRefs(useFreespinsStore())
const freespin = computed(
  () =>
    freespins.value.filter(
      (item) => item.userFreespinProgramId === Number(props.id),
    )[0],
)

const { freespinBetAmount } = useFreespin(freespin)

const { getCurrencyById } = useCurrenciesStore()
const readMoreUrl = usePublicFileUrl('publicFileFreespinsTerms')

const rewardCurrencyCode = computed(() =>
  freespin.value
    ? getCurrencyById(freespin.value.rewardCurrencyId)?.code
    : 'USDT',
)

const { format: formatCrypto } = useCryptoFormatter({
  currency: rewardCurrencyCode.value,
})
const gameIds = computed(() => freespin.value?.casinoGameToChooseIds)
const { data: games, execute: fetchGames } = useStFetch('/casino/game/find', {
  method: 'POST',
  server: false,
  body: computed(() => ({
    params: {
      gameId: gameIds.value,
    },
    pagination: {
      orderBy: [
        {
          fieldName: 'name' as const,
          sortOrder: 'DESC' as const,
        },
      ],
    },
  })),
  watch: false,
  immediate: false,
  transform: (response) => response.data,
})

const gamesNames = computed(() => games.value?.map(({ name }) => name))

watch(
  () => gameIds.value,
  (newGamesIds) => {
    if (newGamesIds?.length) {
      fetchGames()
    }
  },
)

const rollingData = computed(() => {
  const {
    rollingMultiplier = '0',
    rollingDurationPeriodInHours: rollingPeriodInHours = 0,
    rollingMinBetRate = null,
    rollingMaxBetRate = null,
    rollingCasinoCategoryIds = null,
  } = freespin.value ?? {}

  return {
    rollingMultiplier,
    rollingPeriodInHours,
    rollingMinBetRate,
    rollingMaxBetRate,
    rollingCasinoCategoryIds,
  }
})

const {
  rollingMultiplier,
  filteredCategoriesNames,
  formattedRollingMinBetRate,
  formattedRollingMaxBetRate,
} = useRollingData(rollingData)
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
}
</style>
